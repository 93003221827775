
.certifications__cards {
    display:grid;
    grid-template-columns: 1fr 5fr;
    gap:1.5rem;

}

.certifications__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.certifications__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.certifications__card-icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 0rem;
}

.certifications__card h5 {
    font-size: 0.95rem;
}

.certifications__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.certifications__content p {
    margin: 2rem 0 2.6rem;
}

.certifications__summary-icon {
    margin-top: 6px;
    color: var(--color-primary);
    
}

.certifications__summary-details {
    display: grid;
    grid-template-columns: 3% 90%;
    gap: 1rem;
    text-align: left;
}



/* =============== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width:1024px) {
    .certifications__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .certifications__me {
        width:50%;
        margin: 2rem auto 4rem;
    }

    .abount__content p {
        margin: 1rem 0 1.5rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width:600px) {
    .certifications__me {
        width:65%;
        margin: 0 auto 3rem;
    }

    .certifications__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .certifications__content {
        text-align:center;
    }

    .certifications__content p {
        margin: 1.5rem 0;
    }
}